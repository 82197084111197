import {Component, OnInit} from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { register as SwiperRegister } from 'swiper/element/bundle';
import { Platform } from "@ionic/angular";

SwiperRegister();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private platform: Platform
  ) {}

  ngOnInit() {
    // When the platform is ready, perform some actions.
    this.platform.ready().then(() => {
      // Show dark content for Android and iOS.
      StatusBar.setStyle({style: Style.Dark});
      // Hide the splash screen.
      SplashScreen.hide();
    });
  }
}
