<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="onCancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Select a Sign Out Option</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list lines="none">

    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-button expand="block" size="large" (click)="onSearchSignOut()" style="max-width: 350px;margin: 0 auto;">Search</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-item class="ion-margin-bottom">
      <ion-label class="ion-text-wrap ion-text-center">
        <h4>Search and select the person to sign them out</h4>
      </ion-label>
    </ion-item>

    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-button expand="block" size="large" (click)="onCodeSignOut()" style="max-width: 350px;margin: 0 auto;">Enter Code</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-item>
      <ion-label class="ion-text-wrap ion-text-center">
        <h4>This is a code texted to your {{ utils.getLangTerm('mobile.view', 'Mobile Phone Number') }}.</h4>
      </ion-label>
    </ion-item>

  </ion-list>
</ion-content>
