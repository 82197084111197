import { Component, OnInit, Input } from '@angular/core';
import { SiteModel } from 'src/app/models/site';
import { ApiService } from 'src/app/services/api.service';
import { HazardModel } from 'src/app/models/hazard';
import { ModalController } from '@ionic/angular';
import { ApiRequestService } from 'src/app/services/api-request.service';
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-site-induction',
  templateUrl: './site-induction.page.html',
  styleUrls: ['./site-induction.page.scss']
})
export class SiteInductionPage implements OnInit {

  @Input() site: SiteModel;
  @Input() requiresAcknowledgement = true;

  hazards: HazardModel[] = [];

  limit = 30;
  offset = 0;
  search = '';
  canLoadMore = true;

  acknowledgedInduction = false;

  constructor(
    private oldApi: ApiService,
    private modalCtrl: ModalController,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Load the initial list of hazards.
    this.getHazards(true);
  }

  // Trigger search when the "Enter" key is pressed, iOS fix.
  onSearchKey(event: KeyboardEvent) {
    if (event && event.key === 'Enter') {
      this.getHazards(true);
    }
  }

  // Refresh list when search is canceled.
  onSearchCancel() {
    this.search = '';
    this.getHazards(true);
  }

  /**
   * Get the list of hazards for the site.
   * @param resetOffset Whether to reset the offset to 0.
   */
  getHazards(resetOffset: boolean = false) {
    // Reset the offset if requested.
    if ( resetOffset ) {
      this.offset = 0;
    }
    // Make the request to get Hazards.
    this.api.makeRequest('get', `v1/sites/${this.site.id}/hazards`, {}, {
      limit: this.limit,
      offset: this.offset,
      search: this.search
    }).then((response) => {
      // Clear the current list of sites if the offset should be reset.
      if ( resetOffset ) {
        this.hazards.length = 0;
      }
      // Populate the list of hazards for the site.
      response.data.forEach((hazard) => {
        this.hazards.push(new HazardModel(hazard));
      });
      // Indicate if more records can be loaded.
      this.canLoadMore = this.hazards.length !== response.total;
      // Increment the offset.
      this.offset++;
    });
  }

  /**
   * Close the dialog.
   */
  onClose() {
    this.modalCtrl.dismiss({
      acknowledgedInduction: this.acknowledgedInduction
    });
  }
}
