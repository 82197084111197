import { Injectable } from '@angular/core';
import { SiteModel } from '../models/site';
import { AccountModel } from '../models/account';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  auth: {
    email: string,
    password: string
  } = {
    email: '',
    password: ''
  };

  private _isAuthenticated = false;

  account: AccountModel = new AccountModel();

  site: SiteModel = new SiteModel();

  constructor() { }

  isAuthenticated(_isAuthenticated?: boolean) {
    if ( typeof _isAuthenticated !== 'undefined' ) {
      this._isAuthenticated = _isAuthenticated;
    }
    return this._isAuthenticated;
  }
}
