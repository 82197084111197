export class HazardModel {

    id: number = null;
    name: string = null;
    description: string = null;
    status: string = null;

    constructor(data?: any) {
        this.apply(data);
    }

    apply(data) {
        if ( data ) {
            Object.keys(this).forEach(
                (property) => {
                    if ( Object.keys(data).indexOf(property) > -1 ) {
                        this[property] = data[property];
                    }
                }
            );
        }
        return this;
    }
}
