import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  storage = localStorage;

  constructor() { }

  set(key: string, value: any) {
    const encryptedValue: string = CryptoJS.AES.encrypt(JSON.stringify(value), environment.AES_STORAGE_KEY);
    this.storage.setItem(key, encryptedValue.toString());
  }

  get(key: string) {
    const encryptedValue: string = this.storage.getItem(key);
    if ( !encryptedValue ) {
      return null;
    }

    try {
      const decryptedValue: string = CryptoJS.AES.decrypt(encryptedValue, environment.AES_STORAGE_KEY).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue);
    } catch (error) {
      this.delete(key);
      return null;
    }
  }

  delete(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

}
