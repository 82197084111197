import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(
      m => m.SignInPageModule
    )
  },
  {
    path: 'site-selector',
    loadChildren: () => import('./pages/site-selector/site-selector.module').then(
      m => m.SiteSelectorPageModule
    )
  },
  {
    path: 'visitor-sign-in',
    loadChildren: () => import('./pages/visitor-sign-in/visitor-sign-in.module').then(
      m => m.VisitorSignInPageModule
    )
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(
      m => m.ResetPasswordPageModule
    )
  },
  {
    path: 'visitor-sign-out-selector',
    loadChildren: () => import('./pages/visitor-sign-out-selector/visitor-sign-out-selector.module').then(
      m => m.VisitorSignOutSelectorPageModule
    )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
