import { Component, OnInit } from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {UtilsService} from "../../services/utils.service";
import {AppService} from "../../services/app.service";
import {ApiService} from "../../services/api.service";
import {ApiRequestService} from "../../services/api-request.service";

@Component({
  selector: 'app-visitor-sign-out',
  templateUrl: './visitor-sign-out.component.html',
  styleUrls: ['./visitor-sign-out.component.scss'],
})
export class VisitorSignOutComponent implements OnInit {

  constructor(
      private modalCtrl: ModalController,
      public app: AppService,
      private navCtrl: NavController,
      private oldApi: ApiService,
      public utils: UtilsService,
      private api: ApiRequestService
  ) { }

  ngOnInit() {}

  onCancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  onSearchSignOut() {
      this.navCtrl.navigateRoot(['visitor-sign-out-selector']);
      this.onCancel();
  }

  onCodeSignOut() {
    this.utils.showMessage(
        'Sign Out Code Required',
        'Please enter the sign out code that was texted to you. If you do not have it, please contact the manager.',
        [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Sign Out',
            handler: (data) => {

              this.oldApi.request('post', 'tablet/signout', {
                site_id: this.app.site.id,
                signout_code: data.signout_code
              }).then(
                  (response) => {
                    this.utils.showToast('You successfully signed out. Thank you.');
                  },
                  (errorResponse) => {
                    this.utils.showMessage('Sign Out Error', 'We were unable to sign you out. ' +
                        'Please contact the manager for assistance. <br><br>' + errorResponse.error.message);
                  }
              );

            }
          }
        ],
        [
          {
            name: 'signout_code',
            type: 'tel',
            placeholder: 'Enter your sign out code'
          }
        ]
    );
  }

}
