import { Injectable } from '@angular/core';
import { AlertController, ToastController, ModalController, LoadingController } from '@ionic/angular';

// Import the default language file. It is replaced when the app is compiled.
import * as languageJsonFile from 'src/assets/languages/default.json';
import * as _ from 'lodash'; // Lodash is used to dynamically access properties of the default language file.
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) { }

  async showMessage(title: string, message: string, buttons: any = ['Ok'], inputs: any = []) {
    const alert = await this.alertCtrl.create({
      header: title,
      message,
      buttons,
      inputs
    });
    await alert.present();
  }

  async showToast(message: string, duration: number = 3000, buttons: any = ['Ok']) {
    const toast = await this.toastCtrl.create({
      message,
      duration,
      buttons
    });
    await toast.present();
  }

  async showModal(component: any, componentProps: any = {}) {
    const modal = await this.modalCtrl.create({
      component,
      componentProps,
      backdropDismiss: false,
      keyboardClose: false
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async showLoader(message: string = 'Loading. Please wait...') {
    const loader = await this.loadingCtrl.create({
      message
    });
    await loader.present();
    return loader;
  }

  getStaticProfilePic() {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAOB3pUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAHjarZlpVuQ6Ekb/' +
    'axW9BM0hLUdTnNM76OX3DaeBIgt4RfHIQzpxyrIUwzcYd/73X3X/4SenXl0u0mqv1fOTe+5x8KH5x8/jGHy+3q+f9fJdeH/evX4ROZU4psef9dzjB+fL2wWS7/Pz/Xkn6' +
    '56n3ROF14mvn2R3ts/3uHZPlOLjfLj/dv2+buRftnP/xnVPe0/+/HcWgrEL86Xo4kkhed6r3SWxgtTT4Fiud4l2Jj8+8x5T/jh27vXjU/BePz3Fzo/7fHofCufrPaA+xeg' +
    '+H8rT+fR6m/huReHtzu++CPVtac+xU91N9Tx2N3IlUtXdm3rZyvWJgZNQpuuyykv4LXyW69V5Nba4yNgmm5PXcqGHSLQ15LDDCBrOdVxhscQcTyTcMcYV03WuEf4eV3qkg' +
    'FfQKKRnu9TIxCJridPxdS3hum+/7rdC4847MDIGJgtc8dvLfXTyb16vE6la6Ybg22usWFe0mmYZljl7ZxQJCXrHtFzxvV7OPyXnTmwig+UKc2ODw8/HFLOEt9pKV54T44r' +
    'Pzj9aI8i+JyBE3LuwmJDIgK8hFasFiVFCII6N/AxWTpHHSQZCKXEHp+QmpUpyWrR7c42Ea2ws8XEaaCERJVWapFkDkaycC/UjuVFDo6SSXSmlFimt9DJqqrmWWqtUw6ghS' +
    'bIUqSLSpMtoqeVWWm3SWutt9NgTEFZ67eJ6672PwU0HUw+uHowYY8aZZp5l1imzzT7HonxWXmXVJautvsaOO23af9ctbrfd9zjhUEonn3LqkdNOP0OpNU2atWhV0aZdx2v' +
    'W7qy+z1p4ytzXWQt31ixj+Ronb1njtMjLFMHgpFjOyFjMgYyLZYCCjpYz30LO0TJnOfM90hQlkrVQLDk7WMbIYD4hFg2vuXvL3Jd5cyV/K2/xs8w5S92/kTlnqbsz93veP' +
    'sjaHhejpCtB1oUWU58UYGPAaSO2YZz058eRrRXGqZWN+ub6ITbzHBBzlN5UjuG7b8y990zqq7ZW9cTao9/x1D3Gnln7KDUlFklyRcNwbCzNE/qZXWrNOktrZ80KP2lvK7C' +
    'psnRvOrbZZ25CDNo4pwLbQeoMlfAcV8tYwN/JfTavpODk1Lh2UlMznJOWZzkWcRFWQWzVCkJq5oaixqwdrgtuaQklZvWZeQ1uyQoAPqZKj7nEsdJUNpWN9EvIJXx8dJ998' +
    'WfHDKqHuNdazrA9pEXJ9BVHjCicms5ijI5ae5cy5hmgF2ixZJ7a/fFC8s5hBG3B7gkx6Z+7a6uHQhuN/UzxegmmGogTFURjTsvjociLUtQrj0Vp7tWLJ2+Nrtya3Kpl7sS' +
    'UOR0tp0mlvDdT0fM5VcvKylT+nrQe/bmlp6UyZs/FRI3t0o7u8aFQAjvWRWO2csJcVMEpWRZLpQYShb5I0izi54mRNXPdCOPQvLHSbNNJqod9ISHOosVYlCQWtm16WZsY0' +
    'bHUXtReREhsmas3ajWWHJuEM1sq3hDSl3G4g+ajgSbqYZ/FtbkaN8cAO2tdOiTrGnMVWZkl77nUn0Lr9XPEc2PXkxXN8lLC1oDKyAcVmMJ32899OmAIJZE7babVz4wsqaV' +
    'TIpG9E3cWEmg7PzRvC5kjsvAc2mEL16JfIuizWJsfMyFiDqIvS6WNDHAPDefXHlw6gVK/SuvxBO3djbq4Idmnugi6LkDsWhVd9E/HRdvTwLR/a07pOeI6g0GsJuNlXSZfO' +
    'bSu68x6bVWpa6g5Wcb7zpX6mrP4iSSi6ojRyeGgmRs97UlkiXNn2h08ykMZfWbyrDmtmiPwSZ/3AiJEmWD1IkRxXZF134fGX48dLDLdLOIonTBpiLJLSqf31IYO0gU6hiG' +
    'cqJNtGCSmEFnWaCwns0ihX+MGTyd4I4qGLD1FArQrcIAgVLJOtpceWOsI3S1/Aiju/QmE0dkDx6QAMh9Io0iKZGLVS9nBmZ3mVnpzpMrGwuiFekgOMmg10uotRE/PgZAhg' +
    'ZoEXrNsscxlapOFSjM4Smtt1QoXVps9TBlkcjs9rB0WAHDY/9+H3tmHC+ToQLN1O1FIfCrGEobtYRSZtDccsmgBRQ9AMXqmLYz6QgZQJ8H9zQKmYS4NpXbfek1Hr9WuikL' +
    'Zr7cFrfnKbswt7cbJ/s5JbO2fooL702WQJJqzEGnwIKEuwF+tY50wUkeRubAXZTNrUlSwoeBsG2TcSCGgd+CBJsW6ETA4GAgU9gFMKZAdTA0Bu5VuO8ehoQr+JM+ISgNpM' +
    'MtEe4AaWV+R/Q+Ozj5Q6xNAGFriUcQP1YA26rO2UgM4K/CelbknbbtXHFM78DL809BYwWgGfdQM7kh8H9pPYmtHNlQvEwAWiBT0u2xSrr7DCggmc+S2j3c94b5P0zIWiKW' +
    '7ZsCwsIA81aurK3PnFjY8bj0Lc/cEocKDpQwrEySIyVbU3255goY1eysIwLBAdqQwzRBcD9oMj3cgtpW1w3qGyU2QinPPYphxNpFoQPA+SKtmuFmewNd9isqo0W4dzoqo2' +
    'bg3mqPB38KdUNNATjW4WBOmMwsxFbLIsDzqvXkQgSpq4sHhki/KWYFVUFttFyCmYoT6ZM/QKgzbOYnJ7i24Pea+DT16Kg7bZ/hOAd1H99EXAiGEqjg6FlIH2IZs6nHjutN' +
    'CsCBJNkKBbWpeNCmUVNywhwFg25KzjZckJjgvVp3scMMuxFUTO9iWO4FKw5HaAXHUawOjA8JlNO+4kHtczDWwLMh28/LtQh1MESJiNPRzmqhjHxexNi/DDeFSLh0QAwooZ' +
    '9dHhOCRNRqQtWusIUZwrIW55YOq3GjUDDpLr1bsYCvToyEvd7UzEj7Ocu6loLrxSQPGQXJ1e7DB/NATbOttKag7bI1PaHe6CRrd0dW90R179gba+lFMdaAJ+E4YCPKDh0i' +
    'jkoDEias0yDgwF1IElwXb+SNM0J0i9ggoBRjGrgSQssau0bK7e1q/YvyIaEHJAhOw/idd6H6isl+OllFnW9QdY4Z4VyQ61gtgTex7ISmsY5DHE2sDiET4eWIaG45iIQP1F' +
    'HoftbvEHaJYJtEzt+YrWhqgZK+QHR2OwgaCr9l2bAZ6ssKqBMIeMsm6GISN9+gQjsmsnbE/cuHByoBew1ajptu0WSI2LA0q08wg5SKKNjUNmurocrAM3X2/qz4+/jJRwcq' +
    'mCh6hD/clJn0TGgcHj6hqJS/AoBspehOmID3+FZJkxUO9AyrpS4YyqNfQRlpwCfg205nsG93E8BZlpY1FWfNCR4tlqmDS0gTeA+6O6iKSC96o01oKL9QFaQ7VWbVnTMS2x' +
    '3By2UFvKgwEhcrstR9pbCfhaW3RQBa+Cjc0YEB8e6Mb/eUh1JLXDpWq0EfF/6k9crgEB2nUlkk6yRvOE3jI3fapxu2oonRJLk3rMTyxMtGNQiyXTsHLXaXhue6Q3033neb' +
    '8BsxtCm+ahf6EjP1tafSSDy8LgntfluSvRV1LsufJtih3aaB7Wdbmw7zz+2U9L+pe0rsFeXct6LGcXxezwdchFGqHHA6lKoItn2hHAWwRv2YQjdJgGmChYLMi1u6Kj/Qcz' +
    'LJd6VuGw/RGNpvt4ZuzrwUWqx9FVx/f4ddiDxlywm25HXKa0/YAh0zAhdQdSMfsKw4B0E95zl1b84bto9q13R6DRLylpygvfu0AGzAYsPmtqIkebpjNiTIpJUW8O8YBTwR' +
    'KpK+Yyj2fwLiws4wHBlsxXxNMiXlH6ph6OxjnaI92IFiG0FbUaBl4eYQWXMJ+l2keZH7HwCkMCrMgO04skE3C/nmJ9DsGZuKXq4e7GFJXsSfoDFWMn803FkFcSEJjN4GDA' +
    'ioCZIsELejZ3oQ/YvXlfxbpgY9fbe0PjxRntKesNrsHFb27HjsUCIxdh5GtbKBjUBcLeHLFo9mS0EGwHoUoL3WGRTHvDiAvNi7BYWklUSezdKgDBxLgZQI20PmoxEHUpvh' +
    'cZsSJENiTuj1wkMpCmjecFAPrg816mvqemcA2K0VzKe0rkwIvrCjDUSLBQyQA+LQHBiZL8c1IvNrM3eFkz7WbYdgGFK5hqs3kUj2FqjBYR4K7EM4owdvjvSP2xMuqwYdmj' +
    '73/nQcIvzmZZiJF8BF5IlJzhZjpNsUmmkh3PSM3MXXzJDh5dcvHzuywrQAxXZ1qGcBvo0Wjr60bT/aZ+AtZQqMjWtdxTKI4DukU44cLQW8AUNVuuI0tkfo11hBhv5y3PXB' +
    'oC13h9vIk9HoSM6GhA5Om2r0xB0Y2LRwNBnTVWCirDqyzajNL7emW7q8t/6cTacpqnl9msocUg1sbAqO98IbZTPOBPil7A8EgSEuDLUCO09h7V49hMrJBIHiNIyU8pmnMB' +
    'h8gatNHDQemjz3HGABO5RBKJv3LmjkiP9JO5FWn/esCgowb1aJ7xJrblJOAa0k+IP9sOXj5CEpDiTC5/X/tE8n57aP7YoDBPPQc5pweAWaPNjSgskrB7kEeZBOZg3mi9IZ' +
    'L0TQG7Q3NIKlNX1RBfYlgEHUtYIU3fADQJqbkEhID0kc0zecV2VOZsOzRQNds5h0zIA+L/qBK49yvR9j/IPO/I7U+n6gYXbeHLgCeNhs9UxHhUDcNCSe/G+F+G/KQY++jX' +
    'k3wwkF0r7EwN7LnCHHkETD1seSWXEMbTyQ9BIkDfDw28bTdpWm5eOIUr8LP8dMxppPczy5/+8b97PK3b9zPLn+72v3s8rer3c8ufxvjfnb529Xup2l/+cb9NO0v37ifpv3' +
    'lavfTtL9c7T4cxBc6p1nLbNiFCwd8Vsas9RK29V4+uLwx9N2jMbF/Jrj/A/XE5tTfd9fTAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJT' +
    'UUH4wUHAgcauJTPlgAAB0ZJREFUeNrtnctx20gURS9UszcnAsMRmI5AUATmRGBy01iKjkBSBJKW4oZUBKYjEB3BwBGYkwFDmAWaY3lsyvzg093vnCqWSuUql4jug/teA2h' +
    'IAAAAAAAAAAAAAAAAAAAA8J2MQ9A/s4fZUNLgF/9UudJtOEIIYkGCQtJQ0mv/M/effdhIqvzPr5JWyIMgKaRCIem9/9kGlZfliyvdkqOOIKFLkUsaS/pwQDo0xUbSUtKjK' +
    '92K0UCQkMQYSbpsMSkOZS3pXtKCMgxB+hRjLOmqh7Q4JFXuJd0hCoJ03XDPAxbjl6K40l0zegjSdo8xD6iUOqb0mtCjIEgbckx9OTVI4OssvSiUXQhyshgDSZ8iTg3SBEF' +
    'ak2Mo6SmR1NjFR1e6O0YbQQ6VY+z7DQssvCiUXAiylxy3kqbGvnYl6QJJEOR3csxVXw23CJIgCHIgCYIgB5I0whlz4b9rHMjxne3qnRAEOUaSbpkKP0viU5USy7AcFq5zn' +
    'MrElW6BIPbkGHg5hjjwW9650lWUWLa4Qo69mfsTCoIYSY9C9i4Entq0XyGIoTMic/5gpr5nQ5DE0+Na8TzoFBrmVvsyY3IMJH0Tq1anYGpVy1qC3CLHyZjqRcwI8mxLHji' +
    'N3D8KgCCJccncJkUQZHfvQXo0myIjBEmHEb1H43xAEMoreOGk4/s6BEmgOeeWkvaSGUEYRLBaZp0xiHACw9TLrKQF8atXlFftUiAIgwe7OUcQBg9IkDRrZOZv6+Qp9yGUW' +
    'NCIJAgSX4OeM285ESGIwbNagLxCEPoPMHisUxaEmxMBQYAEQZDjeMu8Ja0RhBILEAQAQQAQBABBwuALwwsIAiGwQhAAEoSzGhzFGkEAdvMPgkSGKx0J0h0VgjBwQImFIHA' +
    'wm5Rf8Jm6IF+Zv5yEEGQ3S+Zv63xGkHgb9XXK9XEgrBCEFIEdzXnK/YcVQT4zjzn5IMjuMmtFmdUajwjCQILR8sqSIAvmc+PcWPiSJgTxq1lI0hwbGVn8sHSzImVWc9y70' +
    'm0QJL1mfcXcbiQ97qx8WWu3u39kfpMeCLI7RSp6kZNYW0oPiwmyTZENc/24Y2cpPUwK4gf4hrl+MCtXOnO37Zh85NaV7o6G/eDGfGLxi1t+Jn1CqXVQabVGEFspsrZ6Vjy' +
    'QhSvdwuqXN72ria+p6Ud2U8n40njGHJBmD7O5pDFH4qe+4421VSsSZEeNLTZ4+L8cF9blIEF+TJGBpCfx8s+tHJwwEARJkANBkAQ5EITG/SQqSRPkoEnfG1e6iWwscS5JD' +
    'hLklCQpJH1Smm/NvXGlu2aUEaSJvmQuaZTIV1pL+ovUQJCmRRl5UWJOkxtJd1zjQJA202Qq6SqyP33lG/E1o4ggXYiSe0nGEYhxwwuFEKRPUS69KCGVXkvVz48jBoIEU3q' +
    'NJH2QVPTYfD+qvkWdUgpBgk6VkaRztb/yVfky6pFVKQSJVZjCp8pbSbmOv41l7T9ftmKwGoUgKadM7n8d7uhftv3DhnQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPz4Psg' +
    'X/gKUUqHrhCkH0m/0D1Q0uv9f0hpsLYoVj7z0bS12e/m5YoMyTCVoJC9aOvw2cywMtsVD/iu33Ut7KyKUSWuBTbjRMK8WKcNhJnJemzEn42PktMiO3WO++9FAPmcWcsvSz' +
    'LlGTJEhGjUL0f1QgpgpHl0b9FGEF6FGOsevtPeolwy7B71RvZbRCkuzJqqnq7T9Iinib/XhHuKp9FJsdY0i1ixC1KTC/tySIRo/BisBKVTuk1iWFj7SxwMQa+x5gyp5Jt5' +
    'ichl11ZwHIMVb8bkAacNOmNs0DlmEr6GzlMkEt68mNOguwhh6X3k8OPLPzrtxFkR7/xRCNunkr1e9s3CIIcELgkGXIAkgTapCMHvMDQzw2ZFUTSHDngJUn8oo09QWYPs1u' +
    '1/4JLiJ9xn0vAWU9yjFRfBATYl4s+LiZmPciRq74IyA2HcAhrSe+6btr7KLHmyAFHkPu5k24P4m9XLxhrOJJR11swnXUox0D1LesAp1YgSSbIFaUVNFFqzR5m10k16b4x/' +
    '8bYQkNsJL3pomHvKkGuGFNokO2+BPEnCOkBMadIFwlCekBbKTKOusR6ttMhQBtcxt6DsNMhtEnub1uKVpBLxhBa5n2UTTrNOXTIn201620mCOkBXdFamXUW4x8N0FWZ1Yo' +
    'gvrzKGTcgQUgPCIC2VrPaEuScIYOOOY9JkILxgo4pohDEbzrNxUHomqG/cyP4BCE9oDdJYhDkLeMEqZRZZzFYDNDXyRlBgBKrK0F8gw7QF3noCcLqFfRK09sCNS1IwRBBz' +
    'wxCFuQV4wMp9SFnIf9xAEfwOmRBAJJq1OlBAEgQMESBIACxJUjX29IDvDAXB8EJAhAQQwQBoEkHQBAABAFAEAAEAUAQAEAQAAQBQBAABAFAEAAEAUAQAAQBSJg/Gvy/Kkk' +
    'XHFIIgIpDAAAAAAAAAAAAAAAAAAAA0Ab/AsgLIzVDqQsEAAAAAElFTkSuQmCC';
  }

  /**
   * By using lodash we can dynamically look for values in nested properties of JSON objects.
   * In this case we are loading a default language file that hosts terms for the software.
   * @param path The object path to use to try and get terms.
   * @param defaultText The default text to display as a fallback.
   * @returns
   */
   getLangTerm(path: string, defaultText: string) {
    return _.get(languageJsonFile, 'default.' + path, defaultText);
  }

  /**
   * Get data from the environment file.
   * Possible config items are: app_name, app_env and is_production.
   * Anything else will return null.
   * @param key The key to get data for.
   */
  getEnvironmentConfig(key: string) {
    switch (key) {
      case 'app_name':
        // The name of the app e.g. (SiteConnect or SetConnect) visitor kiosk.
        return environment.APP_NAME;
        break;
      case 'app_env':
        // The environment as text e.g. Local, Dev, Staging, Production.
        return environment.ENV_AS_TEXT;
        break;
      case 'is_production':
        // Returns whether the app is in production mode or not.
        return environment.production;
        break;
      default:
        return null;
    }
  }

  /**
   * Check if value is empty.
   * @param {any} value - The value to check.
   * @return {boolean} - Returns true if value is considered empty, otherwise false.
   */
  public isEmpty(value) {
    const emptyObjects = ['[]', '{}'];
    return (value == null || (typeof value === "string" && value.trim().length === 0) || (typeof value === "object" && emptyObjects.includes(JSON.stringify(value))));
  }

  /**
   * Retrieves a list of phone country codes.
   *
   * @returns {any[]} - An array of phone country codes.
   */
  public getPhoneCodes() {

    let phoneCodes = [
      { text: '+64  New Zealand', country: 'New Zealand', dial_code: '+64', country_code: 'NZ' },
      { text: '+61  Australia', country: 'Australia', dial_code: '+61', country_code: 'AU' },
      { text: '+1   United States', country: 'United States', dial_code: '+1', country_code: 'US' },
      { text: '+44  United Kingdom', country: 'United Kingdom', dial_code: '+44', country_code: 'GB' },

      { text: '+1   Canada', country: 'Canada', dial_code: '+1', country_code: 'CA' },
      { text: '+7   Russia', country: 'Russia', dial_code: '+7', country_code: 'RU' },
      { text: '+20  Egypt', country: 'Egypt', dial_code: '+20', country_code: 'EG' },
      { text: '+27  South Africa', country: 'South Africa', dial_code: '+27', country_code: 'ZA' },
      { text: '+30  Greece', country: 'Greece', dial_code: '+30', country_code: 'GR' },
      { text: '+31  Netherlands', country: 'Netherlands', dial_code: '+31', country_code: 'NL' },
      { text: '+32  Belgium', country: 'Belgium', dial_code: '+32', country_code: 'BE' },
      { text: '+33  France', country: 'France', dial_code: '+33', country_code: 'FR' },
      { text: '+34  Spain', country: 'Spain', dial_code: '+34', country_code: 'ES' },
      { text: '+36  Hungary', country: 'Hungary', dial_code: '+36', country_code: 'HU' },
      { text: '+39  Italy', country: 'Italy', dial_code: '+39', country_code: 'IT' },
      { text: '+40  Romania', country: 'Romania', dial_code: '+40', country_code: 'RO' },
      { text: '+41  Switzerland', country: 'Switzerland', dial_code: '+41', country_code: 'CH' },
      { text: '+43  Austria', country: 'Austria', dial_code: '+43', country_code: 'AT' },

      { text: '+45  Denmark', country: 'Denmark', dial_code: '+45', country_code: 'DK' },
      { text: '+46  Sweden', country: 'Sweden', dial_code: '+46', country_code: 'SE' },
      { text: '+47  Norway', country: 'Norway', dial_code: '+47', country_code: 'NO' },
      { text: '+48  Poland', country: 'Poland', dial_code: '+48', country_code: 'PL' },
      { text: '+49  Germany', country: 'Germany', dial_code: '+49', country_code: 'DE' },
      { text: '+51  Peru', country: 'Peru', dial_code: '+51', country_code: 'PE' },
      { text: '+52  Mexico', country: 'Mexico', dial_code: '+52', country_code: 'MX' },
      { text: '+53  Cuba', country: 'Cuba', dial_code: '+53', country_code: 'CU' },
      { text: '+54  Argentina', country: 'Argentina', dial_code: '+54', country_code: 'AR' },
      { text: '+55  Brazil', country: 'Brazil', dial_code: '+55', country_code: 'BR' },
      { text: '+56  Chile', country: 'Chile', dial_code: '+56', country_code: 'CL' },
      { text: '+57  Colombia', country: 'Colombia', dial_code: '+57', country_code: 'CO' },
      { text: '+58  Venezuela', country: 'Venezuela', dial_code: '+58', country_code: 'VE' },
      { text: '+60  Malaysia', country: 'Malaysia', dial_code: '+60', country_code: 'MY' },

      { text: '+62  Indonesia', country: 'Indonesia', dial_code: '+62', country_code: 'ID' },
      { text: '+63  Philippines', country: 'Philippines', dial_code: '+63', country_code: 'PH' },

      { text: '+65  Singapore', country: 'Singapore', dial_code: '+65', country_code: 'SG' },
      { text: '+66  Thailand', country: 'Thailand', dial_code: '+66', country_code: 'TH' },
      { text: '+81  Japan', country: 'Japan', dial_code: '+81', country_code: 'JP' },
      { text: '+82  South Korea', country: 'South Korea', dial_code: '+82', country_code: 'KR' },
      { text: '+84  Vietnam', country: 'Vietnam', dial_code: '+84', country_code: 'VN' },
      { text: '+86  China', country: 'China', dial_code: '+86', country_code: 'CN' },
      { text: '+90  Turkey', country: 'Turkey', dial_code: '+90', country_code: 'TR' },
      { text: '+91  India', country: 'India', dial_code: '+91', country_code: 'IN' },
      { text: '+92  Pakistan', country: 'Pakistan', dial_code: '+92', country_code: 'PK' },
      { text: '+93  Afghanistan', country: 'Afghanistan', dial_code: '+93', country_code: 'AF' },
      { text: '+94  Sri Lanka', country: 'Sri Lanka', dial_code: '+94', country_code: 'LK' },
      { text: '+95  Myanmar', country: 'Myanmar', dial_code: '+95', country_code: 'MM' },
      { text: '+98  Iran', country: 'Iran', dial_code: '+98', country_code: 'IR' },
      { text: '+212 Morocco', country: 'Morocco', dial_code: '+212', country_code: 'MA' },
      { text: '+213 Algeria', country: 'Algeria', dial_code: '+213', country_code: 'DZ' },
      { text: '+216 Tunisia', country: 'Tunisia', dial_code: '+216', country_code: 'TN' },
      { text: '+218 Libya', country: 'Libya', dial_code: '+218', country_code: 'LY' },
      { text: '+220 Gambia', country: 'Gambia', dial_code: '+220', country_code: 'GM' },
      { text: '+221 Senegal', country: 'Senegal', dial_code: '+221', country_code: 'SN' },
      { text: '+222 Mauritania', country: 'Mauritania', dial_code: '+222', country_code: 'MR' },
      { text: '+223 Mali', country: 'Mali', dial_code: '+223', country_code: 'ML' },
      { text: '+224 Guinea', country: 'Guinea', dial_code: '+224', country_code: 'GN' },
      { text: '+225 Ivory Coast', country: 'Ivory Coast', dial_code: '+225', country_code: 'CI' },
      { text: '+226 Burkina Faso', country: 'Burkina Faso', dial_code: '+226', country_code: 'BF' },
      { text: '+227 Niger', country: 'Niger', dial_code: '+227', country_code: 'NE' },
      { text: '+228 Togo', country: 'Togo', dial_code: '+228', country_code: 'TG' },
      { text: '+229 Benin', country: 'Benin', dial_code: '+229', country_code: 'BJ' },
      { text: '+230 Mauritius', country: 'Mauritius', dial_code: '+230', country_code: 'MU' },
      { text: '+231 Liberia', country: 'Liberia', dial_code: '+231', country_code: 'LR' },
      { text: '+232 Sierra Leone', country: 'Sierra Leone', dial_code: '+232', country_code: 'SL' },
      { text: '+233 Ghana', country: 'Ghana', dial_code: '+233', country_code: 'GH' },
      { text: '+234 Nigeria', country: 'Nigeria', dial_code: '+234', country_code: 'NG' },
      { text: '+235 Chad', country: 'Chad', dial_code: '+235', country_code: 'TD' },
      { text: '+236 Central African Republic', country: 'Central African Republic', dial_code: '+236', country_code: 'CF' },
      { text: '+237 Cameroon', country: 'Cameroon', dial_code: '+237', country_code: 'CM' },
      { text: '+238 Cape Verde', country: 'Cape Verde', dial_code: '+238', country_code: 'CV' },
      { text: '+239 Sao Tome and Principe', country: 'Sao Tome and Principe', dial_code: '+239', country_code: 'ST' },
      { text: '+240 Equatorial Guinea', country: 'Equatorial Guinea', dial_code: '+240', country_code: 'GQ' },
      { text: '+241 Gabon', country: 'Gabon', dial_code: '+241', country_code: 'GA' },
      { text: '+242 Republic of the Congo', country: 'Republic of the Congo', dial_code: '+242', country_code: 'CG' },
      { text: '+243 Democratic Republic of the Congo', country: 'Democratic Republic of the Congo', dial_code: '+243', country_code: 'CD' },
      { text: '+244 Angola', country: 'Angola', dial_code: '+244', country_code: 'AO' },
      { text: '+245 Guinea-Bissau', country: 'Guinea-Bissau', dial_code: '+245', country_code: 'GW' },
      { text: '+246 British Indian Ocean Territory', country: 'British Indian Ocean Territory', dial_code: '+246', country_code: 'IO' },
      { text: '+248 Seychelles', country: 'Seychelles', dial_code: '+248', country_code: 'SC' },
      { text: '+249 Sudan', country: 'Sudan', dial_code: '+249', country_code: 'SD' },
      { text: '+250 Rwanda', country: 'Rwanda', dial_code: '+250', country_code: 'RW' },
      { text: '+251 Ethiopia', country: 'Ethiopia', dial_code: '+251', country_code: 'ET' },
      { text: '+252 Somalia', country: 'Somalia', dial_code: '+252', country_code: 'SO' },
      { text: '+253 Djibouti', country: 'Djibouti', dial_code: '+253', country_code: 'DJ' },
      { text: '+254 Kenya', country: 'Kenya', dial_code: '+254', country_code: 'KE' },
      { text: '+255 Tanzania', country: 'Tanzania', dial_code: '+255', country_code: 'TZ' },
      { text: '+256 Uganda', country: 'United States', dial_code: '+256', country_code: 'UG' },
      { text: '+257 Burundi', country: 'Uganda', dial_code: '+257', country_code: 'BI' },
      { text: '+258 Mozambique', country: 'Mozambique', dial_code: '+258', country_code: 'MZ' },
      { text: '+260 Zambia', country: 'Zambia', dial_code: '+260', country_code: 'ZM' },
      { text: '+261 Madagascar', country: 'Madagascar', dial_code: '+261', country_code: 'MG' },
      { text: '+262 Reunion', country: 'Reunion', dial_code: '+262', country_code: 'RE' },
      { text: '+263 Zimbabwe', country: 'Zimbabwe', dial_code: '+263', country_code: 'ZW' },
      { text: '+264 Namibia', country: 'Namibia', dial_code: '+264', country_code: 'NA' },
      { text: '+265 Malawi', country: 'Malawi', dial_code: '+265', country_code: 'MW' },
      { text: '+266 Lesotho', country: 'Lesotho', dial_code: '+266', country_code: 'LS' },
      { text: '+267 Botswana', country: 'Botswana', dial_code: '+267', country_code: 'BW' },
      { text: '+268 Eswatini', country: 'Eswatini', dial_code: '+268', country_code: 'SZ' },
      { text: '+269 Comoros', country: 'Comoros', dial_code: '+269', country_code: 'KM' },
      { text: '+290 Saint Helena', country: 'Saint Helena', dial_code: '+290', country_code: 'SH' },
      { text: '+291 Eritrea', country: 'Eritrea', dial_code: '+291', country_code: 'ER' },
      { text: '+297 Aruba', country: 'Aruba', dial_code: '+297', country_code: 'AW' },
      { text: '+298 Faroe Islands', country: 'Faroe Islands', dial_code: '+298', country_code: 'FO' },
      { text: '+299 Greenland', country: 'Greenland', dial_code: '+299', country_code: 'GL' },
      { text: '+350 Gibraltar', country: 'Gibraltar', dial_code: '+350', country_code: 'GI' },
      { text: '+351 Portugal', country: 'Portugal', dial_code: '+351', country_code: 'PT' },
      { text: '+352 Luxembourg', country: 'Luxembourg', dial_code: '+352', country_code: 'LU' },
      { text: '+353 Ireland', country: 'Ireland', dial_code: '+353', country_code: 'IE' },
      { text: '+354 Iceland', country: 'Iceland', dial_code: '+354', country_code: 'IS' },
      { text: '+355 Albania', country: 'Albania', dial_code: '+355', country_code: 'AL' },
      { text: '+356 Malta', country: 'Malta', dial_code: '+356', country_code: 'MT' },
      { text: '+357 Cyprus', country: 'Cyprus', dial_code: '+357', country_code: 'CY' },
      { text: '+358 Finland', country: 'Finland', dial_code: '+358', country_code: 'FI' },
      { text: '+359 Bulgaria', country: 'Bulgaria', dial_code: '+359', country_code: 'BG' },
      { text: '+370 Lithuania', country: 'Lithuania', dial_code: '+370', country_code: 'LT' },
      { text: '+371 Latvia', country: 'Latvia', dial_code: '+371', country_code: 'LV' },
      { text: '+372 Estonia', country: 'Estonia', dial_code: '+372', country_code: 'EE' },
      { text: '+373 Moldova', country: 'Moldova', dial_code: '+373', country_code: 'MD' },
      { text: '+374 Armenia', country: 'Armenia', dial_code: '+374', country_code: 'AM' },
      { text: '+375 Belarus', country: 'Belarus', dial_code: '+375', country_code: 'BY' },
      { text: '+376 Andorra', country: 'Andorra', dial_code: '+376', country_code: 'AD' },
      { text: '+377 Monaco', country: 'Monaco', dial_code: '+377', country_code: 'MC' },
      { text: '+378 San Marino', country: 'San Marino', dial_code: '+378', country_code: 'SM' },
      { text: '+380 Ukraine', country: 'Ukraine', dial_code: '+380', country_code: 'UA' },
      { text: '+381 Serbia', country: 'Serbia', dial_code: '+381', country_code: 'RS' },
      { text: '+382 Montenegro', country: 'Montenegro', dial_code: '+382', country_code: 'ME' },
      { text: '+385 Croatia', country: 'Croatia', dial_code: '+385', country_code: 'HR' },
      { text: '+386 Slovenia', country: 'Slovenia', dial_code: '+386', country_code: 'SI' },
      { text: '+387 Bosnia and Herzegovina', country: 'Bosnia and Herzegovina', dial_code: '+387', country_code: 'BA' },
      { text: '+389 North Macedonia', country: 'North Macedonia', dial_code: '+389', country_code: 'MK' },
      { text: '+420 Czech Republic', country: 'Czech Republic', dial_code: '+420', country_code: 'CZ' },
      { text: '+421 Slovakia', country: 'Slovakia', dial_code: '+421', country_code: 'SK' },
      { text: '+423 Liechtenstein', country: 'Liechtenstein', dial_code: '+423', country_code: 'LI' },
      { text: '+500 Falkland Islands', country: 'Falkland Islands', dial_code: '+500', country_code: 'FK' },
      { text: '+501 Belize', country: 'Belize', dial_code: '+501', country_code: 'BZ' },
      { text: '+502 Guatemala', country: 'Guatemala', dial_code: '+502', country_code: 'GT' },
      { text: '+503 El Salvador', country: 'El Salvador', dial_code: '+503', country_code: 'SV' },
      { text: '+504 Honduras', country: 'Honduras', dial_code: '+504', country_code: 'HN' },
      { text: '+505 Nicaragua', country: 'Nicaragua', dial_code: '+505', country_code: 'NI' },
      { text: '+506 Costa Rica', country: 'Costa Rica', dial_code: '+506', country_code: 'CR' },
      { text: '+507 Panama', country: 'Panama', dial_code: '+507', country_code: 'PA' },
      { text: '+508 Saint Pierre and Miquelon', country: 'Saint Pierre and Miquelon', dial_code: '+508', country_code: 'PM' },
      { text: '+509 Haiti', country: 'Haiti', dial_code: '+509', country_code: 'HT' },
      { text: '+590 Guadeloupe', country: 'Guadeloupe', dial_code: '+590', country_code: 'GP' },
      { text: '+591 Bolivia', country: 'Bolivia', dial_code: '+591', country_code: 'BO' },
      { text: '+592 Guyana', country: 'Guyana', dial_code: '+592', country_code: 'GY' },
      { text: '+593 Ecuador', country: 'Ecuador', dial_code: '+593', country_code: 'EC' },
      { text: '+594 French Guiana', country: 'French Guiana', dial_code: '+594', country_code: 'GF' },
      { text: '+595 Paraguay', country: 'Paraguay', dial_code: '+595', country_code: 'PY' },
      { text: '+596 Martinique', country: 'Martinique', dial_code: '+596', country_code: 'MQ' },
      { text: '+597 Suriname', country: 'Suriname', dial_code: '+597', country_code: 'SR' },
      { text: '+598 Uruguay', country: 'Uruguay', dial_code: '+598', country_code: 'UY' },
      { text: '+599 Curaçao', country: 'Curaçao', dial_code: '+599', country_code: 'CW' },
      { text: '+670 Timor-Leste', country: 'Timor-Leste', dial_code: '+670', country_code: 'TL' },
      { text: '+672 Antarctica', country: 'Antarctica', dial_code: '+672', country_code: 'AQ' },
      { text: '+673 Brunei', country: 'Brunei', dial_code: '+673', country_code: 'BN' },
      { text: '+674 Nauru', country: 'Nauru', dial_code: '+674', country_code: 'NR' },
      { text: '+675 Papua New Guinea', country: 'Papua New Guinea', dial_code: '+675', country_code: 'PG' },
      { text: '+676 Tonga', country: 'Tonga', dial_code: '+676', country_code: 'TO' },
      { text: '+677 Solomon Islands', country: 'Solomon Islands', dial_code: '+677', country_code: 'SB' },
      { text: '+678 Vanuatu', country: 'Vanuatu', dial_code: '+678', country_code: 'VU' },
      { text: '+679 Fiji', country: 'Fiji', dial_code: '+679', country_code: 'FJ' },
      { text: '+680 Palau', country: 'Palau', dial_code: '+680', country_code: 'PW' },
      { text: '+681 Wallis and Futuna', country: 'Wallis and Futuna', dial_code: '+681', country_code: 'WF' },
      { text: '+682 Cook Islands', country: 'Cook Islands', dial_code: '+682', country_code: 'CK' },
      { text: '+683 Niue', country: 'Niue', dial_code: '+683', country_code: 'NU' },
      { text: '+685 Samoa', country: 'Samoa', dial_code: '+685', country_code: 'WS' },
      { text: '+686 Kiribati', country: 'Kiribati', dial_code: '+686', country_code: 'KI' },
      { text: '+687 New Caledonia', country: 'New Caledonia', dial_code: '+687', country_code: 'NC' },
      { text: '+688 Tuvalu', country: 'Tuvalu', dial_code: '+688', country_code: 'TV' },
      { text: '+689 French Polynesia', country: 'French Polynesia', dial_code: '+689', country_code: 'PF' },
      { text: '+690 Tokelau', country: 'Tokelau', dial_code: '+690', country_code: 'TK' },
      { text: '+691 Federated States of Micronesia', country: 'Federated States of Micronesia', dial_code: '+691', country_code: 'FM' },
      { text: '+692 Marshall Islands', country: 'Marshall Islands', dial_code: '+692', country_code: 'MH' },
      { text: '+850 North Korea', country: 'North Korea', dial_code: '+850', country_code: 'KP' },
      { text: '+852 Hong Kong', country: 'Hong Kong', dial_code: '+852', country_code: 'HK' },
      { text: '+853 Macau', country: 'Macau', dial_code: '+853', country_code: 'MO' },
      { text: '+855 Cambodia', country: 'Cambodia', dial_code: '+855', country_code: 'KH' },
      { text: '+856 Laos', country: 'Laos', dial_code: '+856', country_code: 'LA' },
      { text: '+880 Bangladesh', country: 'Bangladesh', dial_code: '+880', country_code: 'BD' },
      { text: '+886 Taiwan', country: 'Taiwan', dial_code: '+886', country_code: 'TW' },
      { text: '+960 Maldives', country: 'Maldives', dial_code: '+960', country_code: 'MV' },
      { text: '+961 Lebanon', country: 'Lebanon', dial_code: '+961', country_code: 'LB' },
      { text: '+962 Jordan', country: 'Jordan', dial_code: '+962', country_code: 'JO' },
      { text: '+963 Syria', country: 'Syria', dial_code: '+963', country_code: 'SY' },
      { text: '+964 Iraq', country: 'Iraq', dial_code: '+964', country_code: 'IQ' },
      { text: '+965 Kuwait', country: 'Kuwait', dial_code: '+965', country_code: 'KW' },
      { text: '+966 Saudi Arabia', country: 'Saudi Arabia', dial_code: '+966', country_code: 'SA' },
      { text: '+967 Yemen', country: 'Yemen', dial_code: '+967', country_code: 'YE' },
      { text: '+968 Oman', country: 'Oman', dial_code: '+968', country_code: 'OM' },
      { text: '+970 Palestine', country: 'Palestine', dial_code: '+970', country_code: 'PS' },
      { text: '+971 United Arab Emirates', country: 'United Arab Emirates', dial_code: '+971', country_code: 'AE' },
      { text: '+972 Israel', country: 'Israel', dial_code: '+972', country_code: 'IL' },
      { text: '+973 Bahrain', country: 'Bahrain', dial_code: '+973', country_code: 'BH' },
      { text: '+974 Qatar', country: 'Qatar', dial_code: '+974', country_code: 'QA' },
      { text: '+975 Bhutan', country: 'Bhutan', dial_code: '+975', country_code: 'BT' },
      { text: '+976 Mongolia', country: 'Mongolia', dial_code: '+976', country_code: 'MN' },
      { text: '+977 Nepal', country: 'Nepal', dial_code: '+977', country_code: 'NP' },
      { text: '+992 Tajikistan', country: 'Tajikistan', dial_code: '+992', country_code: 'TJ' },
      { text: '+993 Turkmenistan', country: 'Turkmenistan', dial_code: '+993', country_code: 'TM' },
      { text: '+994 Azerbaijan', country: 'Azerbaijan', dial_code: '+994', country_code: 'AZ' },
      { text: '+995 Georgia', country: 'Georgia', dial_code: '+995', country_code: 'GE' },
      { text: '+996 Kyrgyzstan', country: 'Kyrgyzstan', dial_code: '+996', country_code: 'KG' },
      { text: '+998 Uzbekistan', country: 'Uzbekistan', dial_code: '+998', country_code: 'UZ' },
    ];

    return phoneCodes;
  }

}
