import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { AppService } from './app.service';
import { UtilsService } from './utils.service';
import {ModalController, NavController} from '@ionic/angular';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private app: AppService,
    private storage: StorageService,
    private utils: UtilsService,
    private navCtrl: NavController,
    private modalCtrl: ModalController
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Add the authorization header if none is present.
    if (!request.headers.has('Authorization')) {
      const authorizationToken: string = this.storage.get('AuthToken');
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authorizationToken}`)
      });
    }

    // Uploading files does not need content type application/json.
    if (!(request.body instanceof FormData)) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json')
        });
      }
      if (!request.headers.has('Accept')) {
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json')
        });
      }
    }

    // Define the loader as a null object.
    let loader: Promise<HTMLIonLoadingElement> = null;

    // Create a new loader if the request does not have the X-Skip-Loader header.
    if ( !request.headers.has('X-Skip-Loader') ) {
      loader = this.utils.showLoader();
    }

    return next.handle(request).pipe(tap(
      (response: any) => {

        if ( response.status === 401 && this.app.isAuthenticated() ) {
          // Clear the storage
          this.storage.clear();
          // Show authentication failure message
          this.utils.showToast('Authentication failure. You are now being logged out.');
          // Redirect the user
          this.navCtrl.navigateRoot(['/']);
        }

        // Dismiss the loader if the request does not have the X-Skip-Loader header.
        if ( !request.headers.has('X-Skip-Loader') ) {
          loader.then(_loader => {
            // Dismiss the loader.
            _loader.dismiss();
          });
        }
      },
      error => {
        if(error.status >= 500) {
          if(error.status == 503 && this.app.isAuthenticated()) {
            // Server is in Maintenance Mode
            if(this.modalCtrl) {
              this.modalCtrl.dismiss(); // Close Modals
            }
            // Clear the storage
            this.storage.clear();
            // Remove Authentication
            this.app.isAuthenticated(false);
            // Show error message
            this.utils.showToast('You are now being logged out. Something went wrong with our server, try again later.');
            // Redirect the user
            this.navCtrl.navigateRoot(['/']);
          } else {
            this.utils.showToast('Something went wrong with our server, try again later.');
          }
        }

        // Dismiss the loader if the request does not have the X-Skip-Loader header.
        if ( !request.headers.has('X-Skip-Loader') ) {
          loader.then(_loader => {
            // Dismiss the loader.
            _loader.dismiss();
          });
        }
      }
    ));
  }
}
