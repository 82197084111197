import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  request(method: string, endpoint: string, data: any = {}, headers: any = {}, params: any = {}) {

    let request: Observable<any>;

    const requestUrl = environment.api + endpoint;

    const requestOptions = {
      headers: new HttpHeaders(headers),
      params
    };

    switch ( method.toLowerCase() ) {
      case 'post':
        request = this.http.post(requestUrl, data, requestOptions);
        break;
      case 'put':
        request = this.http.put(requestUrl, data, requestOptions);
        break;
      case 'delete':
        request = this.http.delete(requestUrl, requestOptions);
        break;
      default:
        request = this.http.get(requestUrl, requestOptions);
    }

    return request.toPromise();
  }

}
