<ion-header>
  <ion-toolbar>
    <ion-title>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Induction</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="onClose()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <div class="alert alert-danger" role="alert">
    Please <span *ngIf="requiresAcknowledgement">read the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} induction below and </span>scroll to the bottom to {{ requiresAcknowledgement ? 'acknowledge' : 'close'}}.
  </div>

  <ion-list lines="none">

    <ion-item>
      <ion-label class="ion-text-wrap">
        <h4>Name</h4>
        <p>{{site.name}}</p>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label class="ion-text-wrap">
        <h4>Address</h4>
        <p>{{site.location}}</p>
      </ion-label>
    </ion-item>

  </ion-list>

  <ion-text [innerHTML]="site.induction_content"></ion-text>

  <ion-list *ngIf="hazards.length > 0">
      <ion-list-header>Hazards & Risks</ion-list-header>

      <ion-searchbar
        showCancelButton="focus"
        animated
        mode="ios"
        placeholder="Search Hazards & Risks"
        [(ngModel)]="search"
        (keyup)="onSearchKey($event)"
        (ionCancel)="onSearchCancel()">
      </ion-searchbar>

      <ion-item detail="false" *ngFor="let hazard of hazards">
        <ion-label class="ion-text-wrap">
          {{ hazard.name }} <br>
          <b>Status:</b> {{ hazard.status }} <br>
          <p>{{ hazard.description }}</p>
        </ion-label>
      </ion-item>

      <ion-button (click)="getHazards(false)" *ngIf="canLoadMore" expand="full" fill="clear" size="large">Tap to load more Hazards & Risks.</ion-button>
    </ion-list>

    <ion-list lines="none" *ngIf="requiresAcknowledgement">
      <ion-item class="ion-text-wrap">
        <ion-checkbox slot="start" [(ngModel)]="acknowledgedInduction" labelPlacement="end" justify="start">
          I read and accepted the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} induction provided above.
        </ion-checkbox>
      </ion-item>
    </ion-list>

    <ion-button fill="clear" expand="block" (click)="onClose()" [disabled]="requiresAcknowledgement && !acknowledgedInduction">
      <span *ngIf="requiresAcknowledgement">Acknowledge &</span>&nbsp;Close
    </ion-button>

</ion-content>
