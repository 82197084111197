import {AccountModel} from "./account";

export class SiteModel {

    id: number = null;
    name: string = null;
    location: string = null;
    induction_content: string = null;
    geofence: string = null;
    site_type: string = null;
    site_status: string = null;
    enable_after_hours_notification: boolean = null;
    open_time: string = null;
    close_time: string = null;
    date_modified: number = null;

    parent_site_id: number = null;

    parent_site: SiteModel = {} as SiteModel;

    account: AccountModel = {} as AccountModel;

    constructor(data?: any) {
        this.apply(data);
    }

    apply(data) {
        if ( data ) {
            Object.keys(this).forEach(
                (property) => {
                    if ( Object.keys(data).indexOf(property) > -1 ) {
                        this[property] = data[property];
                    }
                }
            );
        }
        return this;
    }
}
