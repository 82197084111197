import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

  fileUploaderQueue: Promise<any>[] = [];
  fileUploaderQueueInProgress = false;

  constructor(
    private http: HttpClient
  ) { }

  /**
   *
   * Send an API request to a web service and use the retured observable to handle API responses.
   *
   * @param method The request method e.g. GET, POST, PUT and DELETE.
   * @param endpoint The API endpoint to send the request to.
   * @param data The data object that should be sent along with the request, only valid for POST and PUT.
   * E.g. { name: 'John', surname: 'Doe' }
   * @param headers The headers (Object) that should be sent with the request.
   * E.g. { 'Transfer-Encoding': 'gzip' }
   * @param params The request params (Object) that should be sent with the request.
   * The params are appended to the API endpoint.
   * E.g. { limit: 15, offset: 0, search: 'A search term', orderBy: 'name', order: 'desc' }
   */
  makeRequestObservable(
    method: string,
    endpoint: string,
    data: any = {},
    params: any = {},
    headers: any = {}
  ): Observable<any> {

    const requestUrl = environment.api_base + endpoint;

    // set the request options
    const options = {
      headers: new HttpHeaders(headers),
      params
    };

    let request: Observable<any>;

    switch (method) {
      case 'POST':
      case 'post':
        request = this.http.post(requestUrl, data, options);
        break;
      case 'PUT':
      case 'put':
        request = this.http.put(requestUrl, data, options);
        break;
      case 'DELETE':
      case 'delete':
        request = this.http.delete(requestUrl, options);
        break;
      default:
        request = this.http.get(requestUrl, options);
    }

    return request;
  }

  /**
   *
   * Send an API request to a web service and use callbacks to handle responses.
   *
   * @param method The request method e.g. GET, POST, PUT and DELETE.
   * @param endpoint The API endpoint to send the request to.
   * @param data The data object that should be sent along with the request, only valid for POST and PUT.
   * E.g. { name: 'John', surname: 'Doe' }
   * @param headers The headers (Object) that should be sent with the request.
   * E.g. { 'Transfer-Encoding': 'gzip' }
   * @param params The request params (Object) that should be sent with the request.
   * The params are appended to the API endpoint.
   * E.g. { limit: 15, offset: 0, search: 'A search term', orderBy: 'name', order: 'desc' }
   */
  makeRequest(method: string, endpoint: string, data: any = {}, params: any = {}, headers: any = {}): Promise<any> {
    return this.makeRequestObservable(method, endpoint, data, params, headers).toPromise();
  }

  /**
   * Upload files using this. 
   */
  makeFileUploadRequest(endpoint: string, files: File[], params: any = {}, headers: any = {}) {
    return new Promise((resolve, reject) => {
      // Check and attach files
      if ( files.length > 0 ) {
        // Create new form data object
        let formData = new FormData();
        // List of files to get Blobs from
        const fileBlobs: Promise<any>[] = [];
        // Get all files
        files.forEach(async (file: File, index) => {
          fileBlobs.push(this.getFileBlob(formData, file, index));
        });
        // Process all files (extracting blobs) before uploading it.
        Promise.all(fileBlobs).then(() => {
          // Make the request
          resolve(this.makeRequestObservable('post', endpoint, formData, params, headers).toPromise());
        });
      } else {
        reject('No files to upload...');
      }
    });
  }

  // Get file blob data
  private async getFileBlob(formData: FormData, file: File, index: number) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const blobData = new Blob([reader.result], {
          type: file.type
        });
        formData.append(`files[${index}]`, blobData, file.name);
        resolve(blobData);
      };
      reader.onerror = () => {
        reader.abort();
        reject('Unable to get file blob.');
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
