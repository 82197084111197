export class AccountModel {

    name: string = null;
    company_logo_url: string = null;
    country: string = '';

    constructor(data?: any) {
        this.apply(data);
    }

    apply(data) {
        if ( data ) {
            Object.keys(this).forEach(
                (property) => {
                    if ( Object.keys(data).indexOf(property) > -1 ) {
                        this[property] = data[property];
                    }
                }
            );
        }
        return this;
    }
}
